import React, { Component } from 'react';
import { Button, Box, Typography, Container } from '@mui/material';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级 UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 错误日志记录已删除，不做任何操作
  }

  handleReload = () => {
    // 刷新页面
    window.location.reload();
  };

  handleGoHome = () => {
    // 返回首页
    window.location.href = '/';  // 假设首页的路径是 '/'
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', mt: 8 }}>
          <Typography variant="h4" color="error" sx={{ mb: 2 }}>
            哎呀，发生了一个问题！
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            我们遇到了一些技术问题，页面无法正常显示。
            <br />
            请尝试刷新页面或返回首页。
          </Typography>

          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleReload}
              sx={{ padding: '10px 20px', fontSize: '16px', marginRight: '10px', mb: 2 }}
            >
              刷新页面
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleGoHome}
              sx={{ padding: '10px 20px', fontSize: '16px', mb: 2 }}
            >
              返回首页
            </Button>
          </Box>
        </Container>
      );
    }

    return this.props.children; // 正常渲染子组件
  }
}

export default ErrorBoundary;
