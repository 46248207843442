import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // 使用 Switch 和 Route
import { CircularProgress, Box, Typography } from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import { UserProvider } from './contexts/UserContext';

// 懒加载组件
const NotFound = lazy(() => import("./components/NotFound"));
const MarketingPage = lazy(() => import("./MarketingPage"));
const WeChat = lazy(() => import("./WeChat"));
const QQ = lazy(() => import("./QQ"));
const Register = lazy(() => import("./components/Register"));
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./Dashboard"));
const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));
const GuestRoute = lazy(() => import("./components/GuestRoute"));
const DeviceDetails = lazy(() => import("./DeviceDetails"));
const Device = lazy(() => import("./Device"));
const FAQ = lazy(() => import("./FAQ"));
const Payment = lazy(() => import("./Payment"));
const Wallet = lazy(() => import("./WalletPage"));
const Recharge = lazy(() => import("./Recharge"));
const User = lazy(() => import("./User"));
const QueryPage = lazy(() => import("./QueryPage"));
const QueryDetail = lazy(() => import("./QueryDetail"));
const QueryList = lazy(() => import("./QueryList"));
const OrderDetail = lazy(() => import("./OrderDetail"));
const RefundRequest = lazy(() => import("./RefundRequest"));
const RefundDetails = lazy(() => import("./RefundDetails"));
const Share = lazy(() => import("./Share"));
const MobileStatusQuery = lazy(() => import("./MobileStatusQuery"));
const MobileStatus = lazy(() => import("./MobileStatus"));
const Testimonials = lazy(() => import("./Testimonials"));
const Flow = lazy(() => import("./Flow"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const Crypto = lazy(() => import("./Crypto"));
const Download = lazy(() => import("./Download"));
const PricingPage = lazy(() => import("./pages/PricingPage"));

function App() {
  return (
    <UserProvider>
      <Router>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ mt: 2 }}>
                正在加载页面，请稍等...
              </Typography>
            </Box>
          }
        >
          <ErrorBoundary>
            <Switch>
              {/* 未登录用户可以访问注册和登录页面 */}
              <Route
                path="/register"
                render={() => (
                  <GuestRoute>
                    <Register />
                  </GuestRoute>
                )}
              />
              <Route
                path="/login"
                render={() => (
                  <GuestRoute>
                    <Login />
                  </GuestRoute>
                )}
              />
              {/* 受保护路由，只有登录的用户可以访问仪表盘 */}
              <Route
                path="/dashboard"
                render={() => (
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/device/:deviceId"
                render={() => (
                  <ProtectedRoute>
                    <DeviceDetails />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/device"
                render={() => (
                  <ProtectedRoute>
                    <Device />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/wallet/refund-request/:recordId"
                render={() => (
                  <ProtectedRoute>
                    <RefundRequest />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/wallet/refund-details/:recordId"
                render={() => (
                  <ProtectedRoute>
                    <RefundDetails />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/wallet"
                render={() => (
                  <ProtectedRoute>
                    <Wallet />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/user"
                render={() => (
                  <ProtectedRoute>
                    <User />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/mobilestatus"
                exact
                render={() => (
                  <ProtectedRoute>
                    <MobileStatusQuery />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/query/:id"
                render={() => (
                  <ProtectedRoute>
                    <QueryDetail />
                  </ProtectedRoute>
                )}
              />            
              <Route
                path="/queries/mobilestatus"
                exact // 确保精确匹配 "/queries/mobilestatus"
                render={() => (
                  <ProtectedRoute>
                    <MobileStatus />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/queries/:id"
                render={() => (
                  <ProtectedRoute>
                    <OrderDetail />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="/queries"
                render={() => (
                  <ProtectedRoute>
                    <QueryList />
                  </ProtectedRoute>
                )}
              />            
              <Route
                path="/testimonials"
                render={() => (
                  <ProtectedRoute>
                    <Testimonials />
                  </ProtectedRoute>
                )}
              />
              {/* 其他路由 */}
              <Route path="/" exact render={() => <MarketingPage />} />
              <Route path="/wechat" render={() => <WeChat />} />
              <Route path="/qq" render={() => <QQ />} />
              <Route path="/query" render={() => <QueryPage />} />
              <Route path="/faq" render={() => <FAQ />} />
              <Route path="/flow" render={() => <Flow />} />
              <Route path="/share" render={() => <Share />} />
              <Route path="/privacy" render={() => <PrivacyPolicy />} />
              <Route path="/crypto" render={() => <Crypto />} />
              <Route path="/download" render={() => <Download />} />
              <Route
                path="/payment/:deviceId/:serviceId"
                render={() => <Payment />}
              />
              <Route path="/recharge-payment" render={() => <Recharge />} />
              <Route path="/pricing" render={() => <PricingPage />} />
              <Route render={() => <NotFound />} /> {/* 404 页面 */}
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </Router>
    </UserProvider>
  );
}

export default App;
