import React from 'react';
import { createRoot } from 'react-dom/client';  // 导入 createRoot
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

// 找到 root 节点
const container = document.getElementById('root');

// 创建 root
const root = createRoot(container);

// 使用 createRoot 渲染 App
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);
